import React, {useEffect} from "react"
import {useDispatch} from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import {resetRegistration} from "../redux/registration/registrationAction";

const RecordRegistrationPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetRegistration())
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    return (
        <Layout main={false}>
            <SEO title="Registration"/>
            <Header/>
            <div className={"page"}>
                <h2 className={"page-title"}> Thank you for your registration</h2>

            <p>
                You will be receiving your email confirmation soon. Please check also your Junk folder.<br/>You will be able to add options to your registration until 12 October and Post-graduate courses and workshops will be available to book by July<br/><small>(fees applicable will correspond to the modification date)</small></p>

            <p>
                After this date, in your account, you will be able to download your confirmation and your receipt and other information.
            </p>                                                                   
            <p>
                Looking forward to greet you in Seville.
            </p>
            <p>
                <strong>The Union 2020 Team</strong>
            </p>
            </div>
    <div className={"content-footer footer-alone"}>
            <a href="https://union2020.urevent.fr/login" className={"sr-btn-next btn btn-info"} >Login</a>
        </div>
        </Layout>
    )
}

export default RecordRegistrationPage
